import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/apps/main/src/app/MuiDsfrThemeProvider.tsx");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/apps/main/src/app/providers.tsx");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/apps/main/src/app/SentryClientProvider.tsx");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/apps/main/src/app/StartDsfr.tsx");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/apps/main/src/components/HeaderAuthoritySwitchItem.tsx");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/apps/main/src/components/HeaderUserItem.tsx");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/apps/main/src/components/live-chat/LiveChatProvider.tsx");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/node_modules/.pnpm/@codegouvfr+react-dsfr@0.75.3/node_modules/@codegouvfr/react-dsfr/assets/dsfr_plus_icons.scss");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/apps/main/src/app/layout.scss");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/node_modules/.pnpm/@codegouvfr+react-dsfr@0.75.3/node_modules/@codegouvfr/react-dsfr/dsfr/artwork/dark.svg");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/node_modules/.pnpm/@codegouvfr+react-dsfr@0.75.3/node_modules/@codegouvfr/react-dsfr/dsfr/artwork/light.svg");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/node_modules/.pnpm/@codegouvfr+react-dsfr@0.75.3/node_modules/@codegouvfr/react-dsfr/dsfr/artwork/system.svg");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/node_modules/.pnpm/@codegouvfr+react-dsfr@0.75.3/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/apple-touch-icon.png");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/node_modules/.pnpm/@codegouvfr+react-dsfr@0.75.3/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/favicon.ico");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/node_modules/.pnpm/@codegouvfr+react-dsfr@0.75.3/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/favicon.svg");
import(/* webpackMode: "eager" */ "/build/ade548c7-421a-4fb2-b899-1c5c8f78b81f/node_modules/.pnpm/@codegouvfr+react-dsfr@0.75.3/node_modules/@codegouvfr/react-dsfr/next-appdir/DsfrProvider.js")